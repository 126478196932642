<template>
  <v-card flat>
    <v-card-actions class="d-flex align-center">

      <v-btn @click="downloadFile" color="primary" dark>
        <v-icon left>{{ this.icons.mdiFileExcel }}</v-icon>
        Planilha Modelo
      </v-btn>

      <v-spacer></v-spacer>

      <v-file-input class="col-4" outlined label="Importar arquivo de vendas..." @change="fileLoaded($event)"
        hide-details></v-file-input>
    </v-card-actions>
    <v-card-text>
      <v-data-table :items-per-page="25" :page.sync="pageCount" :headers="tableColumns" :items="processedOrders"
        v-model="selectedOrders" :loading="loading" no-data-text="Selecione um arquivo compativel (.xlsx)" dense
        loading-text="Processando Arquivo de Vendas..." show-select item-key="B" @toggle-select-all="filterSelected()"
        @page-count="pageCount = $event" :footer-props="{
          itemsPerPageOptions: [25, 50, 100, -1],
          itemsPerPageText: 'Itens por pagina',
          itemsPerPageAllText: 'Todos',
          disablePagination: true,
          nextIcon: '',
          prevIcon: '',
          pageText: '',
        }">
        <template v-slot:item.data-table-select="{ item, isSelected, select }">
          <v-simple-checkbox v-if="checkCountry(item) && typeChecker(item)" :value="isSelected" @input="select($event)"
            :ripple="false">
          </v-simple-checkbox>
        </template>

        <template v-slot:item.A="{ item }">
          <div :class="{ 'invalid d-flex': !item.A }">
            <span v-if="item.A">
              {{ item.A }}
            </span>
            <span v-else style="opacity: 0.3"> Vazio </span>
          </div>
        </template>

        <template v-slot:item.B="{ item }">
          <div :class="{ 'invalid d-flex': !item.B }">
            <span v-if="item.B">
              {{ item.B }}
            </span>
            <span v-else style="opacity: 0.3"> Vazio </span>
          </div>
        </template>

        <template v-slot:item.C="{ item }">
          <div :class="{ 'invalid d-flex': !item.C }">
            <span v-if="item.C">
              {{ item.C }}
            </span>
            <span v-else style="opacity: 0.3"> Vazio </span>
          </div>
        </template>

        <template v-slot:item.D="{ item }">
          <div :class="{ 'invalid d-flex': !item.D }">
            <span v-if="item.D" :rules="[rules.phoneNumber]">
              {{ item.D | VMask('(##) # ####-####') }}
            </span>
            <span v-else style="opacity: 0.3"> Vazio </span>
          </div>
        </template>

        <template v-slot:item.E="{ item }">
          <span>
            {{ item.E }}
          </span>
        </template>

        <template v-slot:item.F="{ item }">
          <div :class="{ 'invalid d-flex': !typeChecker(item) }">
            <span v-if="typeChecker(item)">
              {{ item.F }}
            </span>
            <span v-else-if="!typeChecker(item) || !item.F" style="opacity: 0.3">
              Tipo de pessoa inválido
            </span>
          </div>
        </template>

        <template v-slot:item.G="{ item }">
          <div v-if="item.G && item.W === '1058'"
            :class="{ 'invalid d-flex': (clearData(item.G).length !== 11 && clearData(item.G).length !== 14) }">
            <span v-if="clearData(item.G).length === 14">
              {{ clearData(item.G) | cnpj }}
            </span>
            <span v-else-if="clearData(item.G).length === 11">
              {{ clearData(item.G) | cpf }}
            </span>
            <span style="opacity: 0.3" v-else>
              Documento inválido
            </span>
          </div>
          <div v-else :class="{ 'invalid d-flex': (!item.G && item.W === '1058') || (item.G && item.W !== '1058') }">
            <span v-if="!item.G && item.W === '1058'" style="opacity: 0.3">
              Vazio
            </span>
            <span v-else-if="item.G && item.W !== '1058'" style="opacity: 0.3">
              Campo documento não deve ser preenchido para tomador fora do Brasil
            </span>
            <span v-else>
              {{ item.G }}
            </span>
          </div>
        </template>

        <template v-slot:item.H="{ item }">
          <div :class="{ 'invalid d-flex': !item.H }">
            <span v-if="item.H">
              {{ item.H }}
            </span>
            <span v-else style="opacity: 0.3"> Vazio </span>
          </div>
        </template>

        <template v-slot:item.I="{ item }">
          <div :class="{ 'invalid d-flex': !item.I }">
            <span v-if="item.I">
              {{ item.I }}
            </span>
            <span v-else style="opacity: 0.3"> Vazio </span>
          </div>
        </template>

        <template v-slot:item.J="{ item }">
          <div v-if="(!item.J && item.W == '1058') || (item.J && item.W != '1058')" class="invalid d-flex">
            <div v-if="(!item.J && item.W == '1058')">
              <span style="opacity: 0.3">
                Vazio
              </span>
            </div>
            <div v-else>
              <span style="opacity: 0.3">
                Campo logradouro não deve ser preenchido para tomador fora do Brasil
              </span>
            </div>
          </div>
          <div v-else>
            <span>
              {{ item.J }}
            </span>
          </div>
        </template>

        <template v-slot:item.K="{ item }">
          <div v-if="(!item.K && item.W === '1058') || (item.K && item.W != '1058')" class="invalid d-flex">
            <div v-if="(!item.K && item.W == '1058')">
              <span style="opacity: 0.3">
                Vazio
              </span>
            </div>
            <div v-else>
              <span style="opacity: 0.3">
                Campo bairro não deve ser preenchido para tomador fora do Brasil
              </span>
            </div>
          </div>
          <div v-else>
            <span>
              {{ item.K }}
            </span>
          </div>
        </template>

        <template v-slot:item.L="{ item }">
          <div :class="{ 'invalid d-flex': item.L }" v-if="item.L && item.W !== '1058'">
            <span style="opacity: 0.3">
              Campo cep não deve ser informado para tomador fora do Brasil
            </span>
          </div>
          <div
            v-else-if="item.L && checkCEP(String(item.L).replace('-', '')) && String(item.L).replace('-', '').length === 8">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <span>{{ item.L | cep }}</span>
                </div>
              </template>
            </v-tooltip>
          </div>
          <div v-else :class="{ 'invalid d-flex': !item.L && item.W === '1058' }">
            <span v-if="item.W === '1058'" style="opacity: 0.3">
              Vazio
            </span>
            <span v-else>
              {{ item.L }}
            </span>
          </div>
        </template>

        <template v-slot:item.M="{ item }">
          <div v-if="(!item.M && item.W === '1058') || (item.M && item.W != '1058')" class="invalid d-flex">
            <div v-if="(!item.M && item.W == '1058')">
              <span style="opacity: 0.3">
                Vazio
              </span>
            </div>
            <div v-else>
              <span style="opacity: 0.3">
                Campo estado não deve ser preenchido para tomador fora do Brasil
              </span>
            </div>
          </div>
          <div v-else>
            <span>
              {{ item.M }}
            </span>
          </div>
        </template>

        <template v-slot:item.N="{ item }">
          <div v-if="(!item.N && item.W === '1058') || (item.N && item.W != '1058')" class="invalid d-flex">
            <div v-if="(!item.N && item.W == '1058')">
              <span style="opacity: 0.3">
                Vazio
              </span>
            </div>
            <div v-else>
              <span style="opacity: 0.3">
                Campo cidade não deve ser preenchido para tomador fora do Brasil
              </span>
            </div>
          </div>
          <div v-else>
            <span>
              {{ item.N }}
            </span>
          </div>
        </template>

        <template v-slot:item.O="{ item }">
          <div v-if="(!item.O && item.W == '1058') || (item.O && item.W != '1058')" class="invalid d-flex">
            <div v-if="(!item.O && item.W == '1058')">
              <span style="opacity: 0.3">
                Vazio
              </span>
            </div>
          </div>
          <div v-else>
            <span>
              {{ item.O }}
            </span>
          </div>
        </template>

        <template v-slot:item.P="{ item }">
          <div v-if="(!item.P && item.W == '1058') || (item.P && item.W != '1058')" class="invalid d-flex">
            <div v-if="(!item.P && item.W == '1058')">
              <span style="opacity: 0.3">
                Vazio
              </span>
            </div>
            <div v-else>
              <span style="opacity: 0.3">
                Campo número não deve ser preenchido para tomador fora do Brasil
              </span>
            </div>
          </div>
          <div v-else>
            <span>
              {{ item.P }}
            </span>
          </div>
        </template>

        <template v-slot:item.Q="{ item }">
          <div :class="{ 'invalid d-flex': !item.Q }">
            <span v-if="item.Q">
              {{ item.Q | dateBR }}
            </span>
            <span v-else style="opacity: 0.3"> Vazio </span>
          </div>
        </template>

        <template v-slot:item.R="{ item }">
          <div :class="{ 'invalid d-flex': !item.R }">
            <span v-if="item.R">
              {{ item.R | dateBR }}
            </span>
            <span v-else style="opacity: 0.3"> Vazio </span>
          </div>
        </template>

        <template v-slot:item.S="{ item }">
          <div :class="{ 'invalid d-flex': !item.S }">
            <span v-if="item.S">
              {{ item.S | moneyBr }}
            </span>
            <span v-else style="opacity: 0.3"> Vazio </span>
          </div>
        </template>

        <template v-slot:item.T="{ item }">
          <div :class="{ 'invalid d-flex': !item.T }">
            <span v-if="item.T">
              {{ item.T }}
            </span>
            <span v-else style="opacity: 0.3"> Vazio </span>
          </div>
        </template>

        <template v-slot:item.U="{ item }">
          <div :class="{ 'invalid d-flex': !item.U }">
            <span v-if="item.U">
              {{ item.U }}
            </span>
            <span v-else style="opacity: 0.3"> Vazio </span>
          </div>
        </template>

        <template v-slot:item.V="{ item }">
          <div :class="{ 'invalid d-flex': !countryChecker(item) }">
            <span v-if="!countryChecker(item)" style="opacity: 0.3">
              Páis Inválido
            </span>
            <span v-else>
              {{ item.V }}
            </span>
          </div>
        </template>

        <template v-slot:item.W="{ item }">
          <div :class="{ 'invalid d-flex': !item.W }">
            <span v-if="item.W">
              {{ item.W }}
            </span>
            <span v-else style="opacity: 0.3"> Vazio </span>
          </div>
        </template>

        <template v-slot:item.X="{ item }">
          <div :class="{ 'invalid d-flex': !item.X }">
            <span v-if="item.X">
              {{ item.X }}
            </span>
            <span v-else style="opacity: 0.3"> Vazio </span>
          </div>
        </template>

        <template v-slot:item.Y="{ item }">
          <div v-if="(!item.Y && item.X == 'product') || (item.Y && item.X != 'product')" class="invalid d-flex">
            <div v-if="(!item.Y && item.X == 'product')">
              <span style="opacity: 0.3">
                Vazio
              </span>
            </div>
            <div v-else>
              <span style="opacity: 0.3">
                Campo produto id não deve ser preenchido para vendas de serviços.
              </span>
            </div>
          </div>
          <div v-else>
            <span>
              {{ item.Y }}
            </span>
          </div>
        </template>

        <template v-slot:footer class="d-flex">
          <v-pagination v-model="page" :length="pageCount"></v-pagination>
        </template>
      </v-data-table>
    </v-card-text>
    <v-card-actions class="d-flex align-center">
      <v-spacer></v-spacer>
      <span>As entradas com campos marcados em vermelho indicam valores invalidos e
        não podem ser importadas ao sistema.</span>
      <v-spacer></v-spacer>
      <v-btn color="success" :disabled="!selectedOrders.length" @click="importSelectedOrders" class="mx-4">
        Importar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import Filters from "@/util/Filters";
import formMixin from "@/util/mixins/formMixin";
import {
  mdiFileExcel,
} from "@mdi/js";

export default {
  mixins: [formMixin],

  data() {
    return {
      downloadLink: 'https://docs.google.com/spreadsheets/d/1SsryHeh4jPYkgbCvLrTfbxmxbxQ6f3G3v-CxBjdhrNU/edit?gid=0#gid=0',
      loading: false,
      tableReady: false,
      file: {},
      a: null,
      tableColumns: [
        { text: "Tipo", value: "A" },
        { text: "Transação", value: "B" },
        { text: "Nome", value: "C" },
        { text: "Telefone", value: "D" },
        { text: "email", value: "E" },
        { text: "Tipo de pessoa", value: "F" },
        { text: "Documento", value: "G" },
        { text: "Produto", value: "H" },
        { text: "ID produto", value: "I" },
        { text: "Logradouro", value: "J" },
        { text: "Bairro", value: "K" },
        { text: "Cep", value: "L" },
        { text: "Estado", value: "M" },
        { text: "Cidade", value: "N" },
        { text: "Número", value: "P" },
        { text: "Data da venda", value: "Q" },
        { text: "Data faturar", value: "R" },
        { text: "Valor", value: "S" },
        { text: "Plataforma", value: "T" },
        { text: "Descrição", value: "U" },
        { text: "País", value: "V" },
        { text: "Código País", value: "W" },
        { text: "Tipo de venda", value: "X" },
        { text: "Código de produto", value: "Y" }
      ],
      processedOrders: [],
      selectedOrders: [],
      page: null,
      pageCount: null,
      icons: {
        mdiFileExcel,
      },
    };
  },

  methods: {
    fileLoaded(event) {
      this.selectedOrders = [];
      this.processedOrders = [];
      if (event) {
        this.file = event;
        this.loading = true;

        let formData = new FormData();

        formData.append("file", this.file);

        this.$http.$post("/order-load", formData).then((response) => {
          for (var result in response) {
            this.processedOrders.push(response[result]);
            this.loading = false;
          }
        });
      }
    },

    importSelectedOrders() {
      this.$http.$post("/order-import", this.selectedOrders).then((res) => {
        this.$router.push("/vendas");
      });
    },

    filterSelected() {
      if (this.selectedOrders.length == 0) {
        this.selectedOrders = this.processedOrders.filter((e) => {
          return this.checkCEP(e.L);
        });
      } else {
        this.selectedOrders = [];
      }
    },

    checkCEP(cep) {
      if (!cep) {
        return false;
      }

      let convertedCep = cep;

      if (cep.length > 8) {
        convertedCep = cep.replace("-", "")
      }

      this.a = /^[0-9]{8}$/.test(convertedCep);

      return /^[0-9]{8}$/.test(convertedCep);
    },

    checkCountry(item) {
      if (item.W !== '1058') {
        const countryData = [
          item.J === null,
          item.K === null,
          item.L === null,
          item.M === null,
          item.N === null,
          item.O === null,
          item.P === null,
        ];

        if (countryData.includes(false)) {
          return false;
        }

        return true;
      }


      return this.checkCEP(item.L);
    },

    checkCNPJ(entry) {
      if (!entry) {
        return false;
      }
      let cnpj = entry.toString().replace(/[^\d]+/g, "");

      if (cnpj == "") return false;

      if (cnpj.length != 14) {
        return false;
      }

      if (
        cnpj in
        [
          "00000000000000",
          "11111111111111",
          "22222222222222",
          "33333333333333",
          "44444444444444",
          "55555555555555",
          "66666666666666",
          "77777777777777",
          "88888888888888",
          "99999999999999",
        ]
      )
        return false;

      let tamanho = cnpj.length - 2;
      let numeros = cnpj.substring(0, tamanho);
      let digitos = cnpj.substring(tamanho);
      let soma = 0;
      let pos = tamanho - 7;
      for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2) pos = 9;
      }
      let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
      if (resultado != digitos.charAt(0)) return false;

      tamanho = tamanho + 1;
      numeros = cnpj.substring(0, tamanho);
      soma = 0;
      pos = tamanho - 7;
      for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2) pos = 9;
      }
      resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
      if (resultado != digitos.charAt(1)) return false;

      return true;
    },

    countryChecker(item) {
      if (!item.V) {
        return false;
      }
      if (item.W === '1058' && item.V.toLowerCase() !== 'brasil') {
        return false;
      }
      if (item.W !== '1058' && item.V.toLowerCase() === 'brasil') {
        return false;
      }
      return true;
    },

    clearData(data) {
      data = String(data)
      return data.replace(/[^0-9]+/g, "")
    },

    typeChecker(item) {
      const type = item.F ? item.F.toLowerCase() : null;
      const w = item.W;

      
      if (!['e', 'f', 'j'].includes(type)) {
        return false;
      }
      
      if ((w !== '1058' && type !== 'e') || (w === '1058' && type === 'e')) {
        return false;
      }
      
      return true;
    },

    downloadFile() {
      const link = document.createElement('a');
      link.href = this.downloadLink;
      link.download = 'PlanilhaModelo.xlsx';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
};
</script>

<style>
.invalid {
  border: solid red 3px;
  border-style: dashed;
  padding: 10px;
}
</style>