var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[_c('v-card-actions',{staticClass:"d-flex align-center"},[_c('v-btn',{attrs:{"color":"primary","dark":""},on:{"click":_vm.downloadFile}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s(this.icons.mdiFileExcel))]),_vm._v(" Planilha Modelo ")],1),_c('v-spacer'),_c('v-file-input',{staticClass:"col-4",attrs:{"outlined":"","label":"Importar arquivo de vendas...","hide-details":""},on:{"change":function($event){return _vm.fileLoaded($event)}}})],1),_c('v-card-text',[_c('v-data-table',{attrs:{"items-per-page":25,"page":_vm.pageCount,"headers":_vm.tableColumns,"items":_vm.processedOrders,"loading":_vm.loading,"no-data-text":"Selecione um arquivo compativel (.xlsx)","dense":"","loading-text":"Processando Arquivo de Vendas...","show-select":"","item-key":"B","footer-props":{
        itemsPerPageOptions: [25, 50, 100, -1],
        itemsPerPageText: 'Itens por pagina',
        itemsPerPageAllText: 'Todos',
        disablePagination: true,
        nextIcon: '',
        prevIcon: '',
        pageText: '',
      }},on:{"update:page":function($event){_vm.pageCount=$event},"toggle-select-all":function($event){return _vm.filterSelected()},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.data-table-select",fn:function(ref){
      var item = ref.item;
      var isSelected = ref.isSelected;
      var select = ref.select;
return [(_vm.checkCountry(item) && _vm.typeChecker(item))?_c('v-simple-checkbox',{attrs:{"value":isSelected,"ripple":false},on:{"input":function($event){return select($event)}}}):_vm._e()]}},{key:"item.A",fn:function(ref){
      var item = ref.item;
return [_c('div',{class:{ 'invalid d-flex': !item.A }},[(item.A)?_c('span',[_vm._v(" "+_vm._s(item.A)+" ")]):_c('span',{staticStyle:{"opacity":"0.3"}},[_vm._v(" Vazio ")])])]}},{key:"item.B",fn:function(ref){
      var item = ref.item;
return [_c('div',{class:{ 'invalid d-flex': !item.B }},[(item.B)?_c('span',[_vm._v(" "+_vm._s(item.B)+" ")]):_c('span',{staticStyle:{"opacity":"0.3"}},[_vm._v(" Vazio ")])])]}},{key:"item.C",fn:function(ref){
      var item = ref.item;
return [_c('div',{class:{ 'invalid d-flex': !item.C }},[(item.C)?_c('span',[_vm._v(" "+_vm._s(item.C)+" ")]):_c('span',{staticStyle:{"opacity":"0.3"}},[_vm._v(" Vazio ")])])]}},{key:"item.D",fn:function(ref){
      var item = ref.item;
return [_c('div',{class:{ 'invalid d-flex': !item.D }},[(item.D)?_c('span',{attrs:{"rules":[_vm.rules.phoneNumber]}},[_vm._v(" "+_vm._s(_vm._f("VMask")(item.D,'(##) # ####-####'))+" ")]):_c('span',{staticStyle:{"opacity":"0.3"}},[_vm._v(" Vazio ")])])]}},{key:"item.E",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.E)+" ")])]}},{key:"item.F",fn:function(ref){
      var item = ref.item;
return [_c('div',{class:{ 'invalid d-flex': !_vm.typeChecker(item) }},[(_vm.typeChecker(item))?_c('span',[_vm._v(" "+_vm._s(item.F)+" ")]):(!_vm.typeChecker(item) || !item.F)?_c('span',{staticStyle:{"opacity":"0.3"}},[_vm._v(" Tipo de pessoa inválido ")]):_vm._e()])]}},{key:"item.G",fn:function(ref){
      var item = ref.item;
return [(item.G && item.W === '1058')?_c('div',{class:{ 'invalid d-flex': (_vm.clearData(item.G).length !== 11 && _vm.clearData(item.G).length !== 14) }},[(_vm.clearData(item.G).length === 14)?_c('span',[_vm._v(" "+_vm._s(_vm._f("cnpj")(_vm.clearData(item.G)))+" ")]):(_vm.clearData(item.G).length === 11)?_c('span',[_vm._v(" "+_vm._s(_vm._f("cpf")(_vm.clearData(item.G)))+" ")]):_c('span',{staticStyle:{"opacity":"0.3"}},[_vm._v(" Documento inválido ")])]):_c('div',{class:{ 'invalid d-flex': (!item.G && item.W === '1058') || (item.G && item.W !== '1058') }},[(!item.G && item.W === '1058')?_c('span',{staticStyle:{"opacity":"0.3"}},[_vm._v(" Vazio ")]):(item.G && item.W !== '1058')?_c('span',{staticStyle:{"opacity":"0.3"}},[_vm._v(" Campo documento não deve ser preenchido para tomador fora do Brasil ")]):_c('span',[_vm._v(" "+_vm._s(item.G)+" ")])])]}},{key:"item.H",fn:function(ref){
      var item = ref.item;
return [_c('div',{class:{ 'invalid d-flex': !item.H }},[(item.H)?_c('span',[_vm._v(" "+_vm._s(item.H)+" ")]):_c('span',{staticStyle:{"opacity":"0.3"}},[_vm._v(" Vazio ")])])]}},{key:"item.I",fn:function(ref){
      var item = ref.item;
return [_c('div',{class:{ 'invalid d-flex': !item.I }},[(item.I)?_c('span',[_vm._v(" "+_vm._s(item.I)+" ")]):_c('span',{staticStyle:{"opacity":"0.3"}},[_vm._v(" Vazio ")])])]}},{key:"item.J",fn:function(ref){
      var item = ref.item;
return [((!item.J && item.W == '1058') || (item.J && item.W != '1058'))?_c('div',{staticClass:"invalid d-flex"},[((!item.J && item.W == '1058'))?_c('div',[_c('span',{staticStyle:{"opacity":"0.3"}},[_vm._v(" Vazio ")])]):_c('div',[_c('span',{staticStyle:{"opacity":"0.3"}},[_vm._v(" Campo logradouro não deve ser preenchido para tomador fora do Brasil ")])])]):_c('div',[_c('span',[_vm._v(" "+_vm._s(item.J)+" ")])])]}},{key:"item.K",fn:function(ref){
      var item = ref.item;
return [((!item.K && item.W === '1058') || (item.K && item.W != '1058'))?_c('div',{staticClass:"invalid d-flex"},[((!item.K && item.W == '1058'))?_c('div',[_c('span',{staticStyle:{"opacity":"0.3"}},[_vm._v(" Vazio ")])]):_c('div',[_c('span',{staticStyle:{"opacity":"0.3"}},[_vm._v(" Campo bairro não deve ser preenchido para tomador fora do Brasil ")])])]):_c('div',[_c('span',[_vm._v(" "+_vm._s(item.K)+" ")])])]}},{key:"item.L",fn:function(ref){
      var item = ref.item;
return [(item.L && item.W !== '1058')?_c('div',{class:{ 'invalid d-flex': item.L }},[_c('span',{staticStyle:{"opacity":"0.3"}},[_vm._v(" Campo cep não deve ser informado para tomador fora do Brasil ")])]):(item.L && _vm.checkCEP(String(item.L).replace('-', '')) && String(item.L).replace('-', '').length === 8)?_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('span',[_vm._v(_vm._s(_vm._f("cep")(item.L)))])])]}}],null,true)})],1):_c('div',{class:{ 'invalid d-flex': !item.L && item.W === '1058' }},[(item.W === '1058')?_c('span',{staticStyle:{"opacity":"0.3"}},[_vm._v(" Vazio ")]):_c('span',[_vm._v(" "+_vm._s(item.L)+" ")])])]}},{key:"item.M",fn:function(ref){
      var item = ref.item;
return [((!item.M && item.W === '1058') || (item.M && item.W != '1058'))?_c('div',{staticClass:"invalid d-flex"},[((!item.M && item.W == '1058'))?_c('div',[_c('span',{staticStyle:{"opacity":"0.3"}},[_vm._v(" Vazio ")])]):_c('div',[_c('span',{staticStyle:{"opacity":"0.3"}},[_vm._v(" Campo estado não deve ser preenchido para tomador fora do Brasil ")])])]):_c('div',[_c('span',[_vm._v(" "+_vm._s(item.M)+" ")])])]}},{key:"item.N",fn:function(ref){
      var item = ref.item;
return [((!item.N && item.W === '1058') || (item.N && item.W != '1058'))?_c('div',{staticClass:"invalid d-flex"},[((!item.N && item.W == '1058'))?_c('div',[_c('span',{staticStyle:{"opacity":"0.3"}},[_vm._v(" Vazio ")])]):_c('div',[_c('span',{staticStyle:{"opacity":"0.3"}},[_vm._v(" Campo cidade não deve ser preenchido para tomador fora do Brasil ")])])]):_c('div',[_c('span',[_vm._v(" "+_vm._s(item.N)+" ")])])]}},{key:"item.O",fn:function(ref){
      var item = ref.item;
return [((!item.O && item.W == '1058') || (item.O && item.W != '1058'))?_c('div',{staticClass:"invalid d-flex"},[((!item.O && item.W == '1058'))?_c('div',[_c('span',{staticStyle:{"opacity":"0.3"}},[_vm._v(" Vazio ")])]):_vm._e()]):_c('div',[_c('span',[_vm._v(" "+_vm._s(item.O)+" ")])])]}},{key:"item.P",fn:function(ref){
      var item = ref.item;
return [((!item.P && item.W == '1058') || (item.P && item.W != '1058'))?_c('div',{staticClass:"invalid d-flex"},[((!item.P && item.W == '1058'))?_c('div',[_c('span',{staticStyle:{"opacity":"0.3"}},[_vm._v(" Vazio ")])]):_c('div',[_c('span',{staticStyle:{"opacity":"0.3"}},[_vm._v(" Campo número não deve ser preenchido para tomador fora do Brasil ")])])]):_c('div',[_c('span',[_vm._v(" "+_vm._s(item.P)+" ")])])]}},{key:"item.Q",fn:function(ref){
      var item = ref.item;
return [_c('div',{class:{ 'invalid d-flex': !item.Q }},[(item.Q)?_c('span',[_vm._v(" "+_vm._s(_vm._f("dateBR")(item.Q))+" ")]):_c('span',{staticStyle:{"opacity":"0.3"}},[_vm._v(" Vazio ")])])]}},{key:"item.R",fn:function(ref){
      var item = ref.item;
return [_c('div',{class:{ 'invalid d-flex': !item.R }},[(item.R)?_c('span',[_vm._v(" "+_vm._s(_vm._f("dateBR")(item.R))+" ")]):_c('span',{staticStyle:{"opacity":"0.3"}},[_vm._v(" Vazio ")])])]}},{key:"item.S",fn:function(ref){
      var item = ref.item;
return [_c('div',{class:{ 'invalid d-flex': !item.S }},[(item.S)?_c('span',[_vm._v(" "+_vm._s(_vm._f("moneyBr")(item.S))+" ")]):_c('span',{staticStyle:{"opacity":"0.3"}},[_vm._v(" Vazio ")])])]}},{key:"item.T",fn:function(ref){
      var item = ref.item;
return [_c('div',{class:{ 'invalid d-flex': !item.T }},[(item.T)?_c('span',[_vm._v(" "+_vm._s(item.T)+" ")]):_c('span',{staticStyle:{"opacity":"0.3"}},[_vm._v(" Vazio ")])])]}},{key:"item.U",fn:function(ref){
      var item = ref.item;
return [_c('div',{class:{ 'invalid d-flex': !item.U }},[(item.U)?_c('span',[_vm._v(" "+_vm._s(item.U)+" ")]):_c('span',{staticStyle:{"opacity":"0.3"}},[_vm._v(" Vazio ")])])]}},{key:"item.V",fn:function(ref){
      var item = ref.item;
return [_c('div',{class:{ 'invalid d-flex': !_vm.countryChecker(item) }},[(!_vm.countryChecker(item))?_c('span',{staticStyle:{"opacity":"0.3"}},[_vm._v(" Páis Inválido ")]):_c('span',[_vm._v(" "+_vm._s(item.V)+" ")])])]}},{key:"item.W",fn:function(ref){
      var item = ref.item;
return [_c('div',{class:{ 'invalid d-flex': !item.W }},[(item.W)?_c('span',[_vm._v(" "+_vm._s(item.W)+" ")]):_c('span',{staticStyle:{"opacity":"0.3"}},[_vm._v(" Vazio ")])])]}},{key:"item.X",fn:function(ref){
      var item = ref.item;
return [_c('div',{class:{ 'invalid d-flex': !item.X }},[(item.X)?_c('span',[_vm._v(" "+_vm._s(item.X)+" ")]):_c('span',{staticStyle:{"opacity":"0.3"}},[_vm._v(" Vazio ")])])]}},{key:"item.Y",fn:function(ref){
      var item = ref.item;
return [((!item.Y && item.X == 'product') || (item.Y && item.X != 'product'))?_c('div',{staticClass:"invalid d-flex"},[((!item.Y && item.X == 'product'))?_c('div',[_c('span',{staticStyle:{"opacity":"0.3"}},[_vm._v(" Vazio ")])]):_c('div',[_c('span',{staticStyle:{"opacity":"0.3"}},[_vm._v(" Campo produto id não deve ser preenchido para vendas de serviços. ")])])]):_c('div',[_c('span',[_vm._v(" "+_vm._s(item.Y)+" ")])])]}},{key:"footer",fn:function(){return [_c('v-pagination',{attrs:{"length":_vm.pageCount},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})]},proxy:true}]),model:{value:(_vm.selectedOrders),callback:function ($$v) {_vm.selectedOrders=$$v},expression:"selectedOrders"}})],1),_c('v-card-actions',{staticClass:"d-flex align-center"},[_c('v-spacer'),_c('span',[_vm._v("As entradas com campos marcados em vermelho indicam valores invalidos e não podem ser importadas ao sistema.")]),_c('v-spacer'),_c('v-btn',{staticClass:"mx-4",attrs:{"color":"success","disabled":!_vm.selectedOrders.length},on:{"click":_vm.importSelectedOrders}},[_vm._v(" Importar ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }